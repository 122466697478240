import Link from 'next/link';
import { MainContainer } from '../components/Container';

const Custom404 = () => {
  return (
    <MainContainer header={{ title: 'Page Not Found | SWYG' }}>
      <main className="relative py-24 md:py-10">
        {/* First Section */}
        <section className="relative mx-auto flex w-full max-w-7xl flex-col items-center space-y-8 py-10">
          <div className="font-logo text-7xl font-semibold tracking-wider text-swygBlue-500">
            404
          </div>
          <div className="pb-4 text-xl font-bold">
            페이지를 찾을 수 없습니다.
          </div>
          <div className="flex">
            <Link href={'/'}>
              <a className="text-base text-swygBlue-500">홈페이지</a>
            </Link>
            <div className="mx-6 border-l" />
            <Link href={'/contents'}>
              <a className="text-base text-swygBlue-500">웹 콘텐츠</a>
            </Link>
            <div className="mx-6 border-l" />
            <Link
              href={
                'https://teamswyg.notion.site/SWYG-4673dcfd50024f45af908bd80e44d5dd?pvs=4'
              }
            >
              <a target="_blank" className="text-base text-swygBlue-500">
                호스팅 가이드
              </a>
            </Link>
          </div>
        </section>
      </main>
    </MainContainer>
  );
};

export default Custom404;
